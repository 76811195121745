import React from "react";
import { Link } from "react-router-dom";
import Image from "../../../Image/Image";
import logo from "./capfooterlogo.svg";
import pic from "../../../../assets/icons/location-pin.png";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="part1">
          <div className="about-footer">
            <Link to={"/"} className="logo--footer" title="logo IP.Captain">
              {/* <Image ImgSrc={logo} /> */}
              <img
                src={logo}
                style={{
                  transform: " translateY(-12px)",
                }}
                alt=""
              />
            </Link>
            <p>
              The world's first decentralized accelerator to commercialize your
              ideas.
            </p>
          </div>
          <ul className="menu-footer">
            <li>
              <Link to="http://ip-captain.com/news"> News</Link>
            </li>
            <li>
              <Link to={"/about"}> About</Link>
            </li>
            <li>
              <Link to={"/faq"}> FAQ</Link>
            </li>
            <li>
              <Link to={"/tos"}> Terms and Conditions</Link>
            </li>
          </ul>
          <ul className="menu-footer">
            <li>
              <Link to="https://ipminter.com/" target="blank">
                {" "}
                IP.Minter{" "}
              </Link>
            </li>

            <li>
              <Link to="https://ip-cafe.com/" target="blank">
                {" "}
                IP.Cafe
              </Link>
            </li>
          </ul>
          <div className="newletter-footer">
            <span className="title">Get In Touch</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img src={pic} alt="" style={{ width: "24px", height: "24px" }} />
              <p>
                10th Floor, Flexi-Space, Mechelsesteenweg 455, 1950 Kraainem,
                Belgium <br />
                Send Email:{" "}
                <Link
                  to={"mailto:info@ipminter.com"}
                  style={{ display: "inline" }}
                >
                  info@ipminter.com
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="copyRight">
          <span>
            {" "}
            Copyright © 2024
            <Link to="https://sensifia.com/" title="sensifia" target="blank">
              Sensifia
            </Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
