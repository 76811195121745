import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/images/logo.webp";

const Header = () => {
  const [scroll, setScroll] = useState(0);
  const handleScroll = () => setScroll(document.documentElement.scrollTop);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const classname = scroll > 50 ? "scrolled" : "";

  return (
    <header className={classname}>
      <Link to="/" className="logo" title="logo IP.Captain">
        <img src={Logo} alt="" />
      </Link>
      <ul className="menu">
        <li>
          <Link to="http://ip-captain.com/news"> News</Link>
        </li>
        <li>
          <Link to={"/about"}> About</Link>
        </li>
        <li>
          <Link to={"/faq"}> FAQ</Link>
        </li>
      </ul>

      <Link
        to="http://app.ip-captain.com/auth/login"
        className="button"
        title="app IP.Captain"
      >
        APPLY
      </Link>
    </header>
  );
};

export default Header;
