import React from "react";
import { HelmetProvider } from "react-helmet-async";
import HomePage from "./screen/home";
import About from "./screen/about";
import FAQ from "./screen/faq";

import "./css/style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TOS from "./screen/tos/Tos";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HelmetProvider>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path={"/about"} element={<About />} />
            <Route path={"/faq"} element={<FAQ />} />
            <Route path={"/tos"} element={<TOS />} />
          </Routes>
        </HelmetProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
