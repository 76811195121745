import React,{ useState } from "react";
import arrowIcon from "../../../../assets/icons/expand_more.png";

const Accordian = (props) => {
    const [open, setOpen] = useState(true);
    let toggleHandler = (e) => {
        setOpen(!open);
    };
    return(
        <div className={`accordion--item ${!open ? 'collapsed' : ''}`}>
            <div className="accordion--header"
             onClick={
                toggleHandler
            }>
                <h2 className="title--accordion">
                    {props.title}
                </h2>
                <i><img src={arrowIcon} alt="" /></i>
            </div>
            <p className='accordion--content'>
                {props.content}
            </p>
        </div>
    )
}

export default Accordian