import React from 'react'
import Layout from '../../components/Layout/Layout';
import './style.css';
import Accordian from './components/accordion';
import { Helmet } from 'react-helmet-async';

const FAQ = () => {
  return (
    <Layout>
        <Helmet>
            <title>FAQ</title>
            <meta name='description' 
                  content="The first decentralized business accelerator in the world. IP-Captain is the first business accelerator using smart accounts and account abstraction."
            />
      </Helmet>
      <div className="faq-page">
          <div className="container">
            <h1 className='title'>FREQUENTLY ASKED QUESTIONS</h1>
            <div className="accordion">
                <Accordian 
                    title="What is IP-Captain?"
                    content="IP-Captain is a decentralized accelerator that empowers innovators by transforming their business ideas into IPT assets, providing investment, mentorship, and strategic connections to fuel the growth of decentralized ventures."
                />
                <Accordian 
                    title="How does IP-Captain work?"
                    content="We turn your business ideas into IPTs that belong to you, ensuring permanent ownership. We then evaluate the potential of your idea to become a decentralized autonomous organization (DAO) and provide investment if your business plan is promising."
                />
                <Accordian 
                    title="How do I submit my business idea to IP-Captain?"
                    content="    You can submit your idea on our platform by following the submission process outlined on our website."
                />
                <Accordian 
                    title="What types of businesses or ideas does IP-Captain support?"
                    content=" IP-Captain accepts applications from all domains. We evaluate the potential of your idea to become a DAO and consider a wide range of industries."
                />
                <Accordian 
                    title="Can I still pursue my idea independently after submitting it to IP-Captain?"
                    content="Yes, you retain ownership of your idea even after submitting it to us as an IPT. You are free to pursue your idea independently if you choose. "
                />
                <Accordian 
                    title="What makes IP-Captain unique?"
                    content="IP-Captain's uniqueness lies in its use of IPTs to secure idea ownership, its focus on decentralized ventures, and its comprehensive support, which includes investment, mentorship, VC introductions, and partnerships with large companies. "
                />
                <Accordian 
                    title="Do I have to give up equity in my business to receive investment from IP-Captain?"
                    content="IP-Captain's investment terms can vary, but we aim to provide support that aligns with your growth goals. Equity arrangements will be discussed during the investment process. "
                />
                <Accordian 
                    title="How long does it take to receive a response after submitting my idea?"
                    content="Response times may vary, but we aim to provide timely feedback and evaluations for all submissions. "
                />
                <Accordian 
                    title="Can IP-Captain help with non-funding needs, such as mentorship or business development?"
                    content="Yes, we offer a range of services beyond investment, including mentorship, business plan refinement, VC introductions, partnerships, and business development support. "
                />
                <Accordian 
                    title="How can I contact IP-Captain for further inquiries?"
                    content="You can reach out to our team through the contact information provided on our website, and we'll be happy to assist you with any questions or concerns. "
                />
               
            </div>
          </div>
      </div>
    </Layout>
  )
}

export default FAQ