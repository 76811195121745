import React from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from '../../configs'
const Layout = ({children}) => {
    return(
        <div>
            <ScrollToTop />
            <Header />
            {children}
            <Footer />
        </div>
    )
}
export default Layout