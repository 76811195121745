// import React from "react";
// import axios from "axios";
// import news1 from "../images/news1.webp";
// import news2 from "../images/news2.webp";
// import news3 from "../images/news3.webp";
// import { useEffect, useState } from "react";
// type Props = {};

// const MediaPot = ({ imageId }) => {
//   const [image, setImage] = useState<any>();
//   const getTumline = async () => {
//     const config = {
//       method: "get",
//       url: `https://ip-captain.com/news/wp-json/wp/v2/media/${imageId}`,
//     };
//     try {
//       const response = await axios(config);
//       console.log("🚀 ~ file: index.tsx:32 ~ getTumline ~ response:", response);
//       setImage(response?.data?.source_url);
//     } catch (error) {}
//   };
//   useEffect(() => {
//     getTumline();
//   }, [imageId]);

//   return <img src={image} alt="" />;
// };
// const NewsSection = (props: Props) => {
//   const [posts, setPosts] = useState();
//   const getPosts = async () => {
//     let s = await axios.get("https://ip-captain.com/blog/wp-json/wp/v2/posts");
//     console.log("🚀 ~ file: NewsSection.tsx:32 ~ getPosts ~ s:", s);

//     setPosts(s?.data);
//   };

//   useEffect(() => {
//     getPosts();
//   }, []);

//   return (
//     <div className="news-section-container">
//       <div className="news-section">
//         <h1>news</h1>

//         <div className="news-card-container">
//           <div className="news-card">
//             <img src={news1} alt="" />

//             <p>
//               <span> Mentorship and Coaching:</span> Our experienced mentors
//               work closely with startups to provide
//               <button className="news-btn">See More</button>
//             </p>
//           </div>
//           <div className="news-card">
//             <img src={news2} alt="" />
//             <p>
//               <span> Workspace:</span> We offer state-of-the-art workspace
//               facilities that provide startups with the resources they...
//               <button className="news-btn">See More</button>
//             </p>
//           </div>
//           <div className="news-card">
//             <img src={news3} alt="" />
//             <p>
//               <span>Networking and Events:</span> We organize regular networking
//               events, workshops, and seminars that allow startups to connect
//               with ...
//             </p>
//             <button className="news-btn">See More</button>
//           </div>
//         </div>
//         <button className="more-news">More</button>
//       </div>
//     </div>
//   );
// };

// export default NewsSection;
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import Image from "../../../../../components/Image/Image";
import "swiper/css";

import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const MediaPot = ({ imageId, url }) => {
  const [image, setImage] = useState<any>();
  const navigate = useNavigate();
  const getTumline = async () => {
    const config = {
      method: "get",
      url: `https://ip-captain.com/news/wp-json/wp/v2/media/${imageId}`,
    };
    try {
      const response = await axios(config);
      console.log("🚀 ~ file: index.tsx:32 ~ getTumline ~ response:", response);
      setImage(response?.data?.source_url);
    } catch (error) {}
  };
  useEffect(() => {
    getTumline();
  }, [imageId]);

  return (
    <a href={url} target="_blank">
      <img src={image} alt="" className="img--news" />
    </a>
  );
};
const Blogs = () => {
  const [posts, setPosts] = useState<any>();
  console.log("🚀 ~ file: index.tsx:14 ~ Blogs ~ posts:", posts);
  const getPosts = async () => {
    const config = {
      method: "get",
      url: "https://ip-captain.com/news/wp-json/wp/v2/posts",
    };
    try {
      const response = await axios(config);
      setPosts(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <div className="blog-section">
      <div className="container title--sec">
        <h3
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          News
        </h3>
        {/* <h4>Let’s help you live your dream</h4> */}
      </div>
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        navigation={true}
        modules={[Navigation, Autoplay]}
        autoplay={{
          delay: 2500,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          720: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1001: {
            slidesPerView: 4,
          },
        }}
        className="slider--blog"
      >
        {posts?.map((data, i) => (
          <SwiperSlide>
            <MediaPot imageId={data?.featured_media} url={data?.link} />
            <div className="info--news">
              <span className="date">{data?.date_gmt?.slice(0, -9)}</span>

              <a href={data?.link} target="_blank" className="title--news">
                {data?.title?.rendered?.replace("&#8217;", "'")}
              </a>

              <a
                style={{
                  color: "green",
                }}
                href={data?.link}
                target="_blank"
                className="more"
              >
                Read More
              </a>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Blogs;
