import ipcaptainBannerLogo from "../images/ipcaption.svg";
import rocket from "../images/rocket.webp";
import BlockChainBanner from "../images/Challenge.webp";
import { Link, useNavigate } from "react-router-dom";

const Section1 = () => {
  const navigate = useNavigate();
  return (
    <section className="section1-container">
      <div className="section1-content">
        <div className="heading">
          <img src={ipcaptainBannerLogo} alt="" />

          <p>
            Your Gateway <br /> to Decentralized <br /> Entrepreneurship
          </p>
        </div>
        <div className="banner">
          <img src={rocket} alt="" />
        </div>
      </div>
      {/* <Link className="challenge" to={"http://app.ip-captain.com/auth/login"}>
        <img src={BlockChainBanner} alt="" />
      </Link> */}
    </section>
  );
};
export default Section1;
