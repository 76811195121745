import React from 'react'
import { Helmet } from 'react-helmet-async';
import Layout from '../../components/Layout/Layout';
import aboutImg from '../../assets/images/about.webp';
import aboutImg2 from '../../assets/images/about2.webp';
import './style.css'

const About = () => {
  return (
    
    <Layout>
      <Helmet>
        <title>About IP.Captain</title>
        <meta name='description' 
        content='Ip-Captain, a cutting-edge decentralized startup accelerator designed to empower and propel the next generation of innovators.' />
      </Helmet>
      <div className="about-page">
          <div className="container">
              <div className='part'>
                <figure className='img--about'>
                  <img src={aboutImg} alt="" />
                </figure>
                <p className="info">
                  <h1 className='title'>ABOUT US</h1>
                  Welcome to Ip-Captain, a cutting-edge decentralized startup accelerator designed to empower and propel the next generation of innovators. At Ip-Captain, we believe in fostering a global community where groundbreaking ideas flourish, and entrepreneurs find the support they need to transform their visions into reality.
                </p>
              </div>
              <div className='part part2' >
                <figure className='img--about'>
                  <img src={aboutImg2} alt="" />
                </figure>
                <p className="info">
                  <h2 className='title2' style={{paddingTop: '40px'}}>Our Mission</h2>
                Ip-Captain is on a mission to redefine the startup acceleration experience through decentralization. We strive to break down barriers, connecting talented minds from around the world and providing them with the resources, mentorship, and opportunities crucial for success.
                </p>
              </div>
              <div className="info" style={{
                  width:'100%',
                  paddingBottom:'30px'
                }}>
                <h3 className='title2'>How We Work</h3>
                <p>
                Ip-Captain leverages blockchain technology to create a transparent, secure, and inclusive ecosystem for startups. Our decentralized approach ensures that every participant, irrespective of their location or background, has an equal chance to thrive.

                </p>
              </div>
              <div className="info" style={{
                  width:'100%',
                  paddingBottom:'40px'
                }}>
                <h3 className='title2'>Join Us in Shaping the Future</h3>
                <p>
                  Whether you're a visionary startup founder, an experienced mentor, or an investor seeking the next big opportunity, Ip-Captain invites you to be part of a decentralized revolution in startup acceleration. Together, let's navigate the future of innovation and entrepreneurship. Welcome to Ip-Captain where ideas set sail!
                </p>
              </div>
          </div>
      </div>
    </Layout>
  )
}

export default About