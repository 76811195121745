import React from "react";
import WhyImage from "./images/why.webp";
import SecureImage from "./images/secure.webp";
type Props = {};

const Section2 = (props: Props) => {
  return (
    <div className="section2-container">
      <div className="section2-content">
        <div className="section2-secure">
          <h1 className="section2-heading border-after">
            {" "}
            Secure Up to $100,000 Investment!
          </h1>
          <p>
            Are you a visionary entrepreneur with a groundbreaking business
            idea? Your journey to success begins here! We are thrilled to
            announce an exclusive opportunity for budding startups to secure up
            to $100,000 in investment. Submit your business idea today for a
            chance to be among the top 10 startups selected for this
            unparalleled investment opportunity. We're looking for innovation,
            passion, and the drive to transform industries.
          </p>
          <a
            href="http://app.ip-captain.com/auth/login"
            target="_blank"
            className="apply-btn"
          >
            Apply Now
          </a>
          <img src={SecureImage} alt="" />
        </div>
        <div className="section2-why">
          <img src={WhyImage} alt="" />
          <h1 className="section2-heading"> Why IP Captain</h1>
          <p>
            At IP-Captain, we empower innovators by transforming their business
            ideas into valuable IPT assets in the decentralized world. With
            IP-Captain, your entrepreneurial dreams find a home, where ideas are
            nurtured, transformed into decentralized autonomous organizations
            (DAOs), and empowered to reshape industries and forge new frontiers
            in the decentralized landscape. Join us in a world where innovation
            knows no bounds, and your success is our commitment.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section2;
