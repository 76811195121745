import { Suspense, lazy } from "react";

import "./main.css";
import "./home.css";

import Layout from "../../components/Layout/Layout";
import Loading from "../../components/Loading/loading";
import Section1 from "./components/redesignComponents/Section1/Section1";
import FeaturesSection from "./components/redesignComponents/Features/FeaturesSection";
import NewsSection from "./components/redesignComponents/News/NewsSection";
import Section2 from "./components/redesignComponents/Section2/Section2";
import { Helmet } from "react-helmet-async";

// const Sec1 = lazy(() => import("./components/Section1"));
// const Sec2 = lazy(() => import("./components/Section2"));
const SecVideo = lazy(() => import("./components/Section2Video"));
// const Sec3 = lazy(() => import("./components/Section3"));
const Sec4 = lazy(() => import("./components/Section4"));

const HomePage = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Helmet>
        <title>IP Captain</title>
        <meta name='description' 
        content='Ip-Captain, a cutting-edge decentralized startup accelerator designed to empower and propel the next generation of innovators.' />
      </Helmet>
      <Layout>
        <div className="main">
          {/* <Sec1 /> */}
          <Section1 />
          <Section2 />
          <SecVideo />
          {/* <Sec2 /> */}
          <FeaturesSection />
          <Sec4 />
          <NewsSection />
          {/* <Sec3 /> */}
        </div>
      </Layout>
    </Suspense>
  );
};

export default HomePage;
