import React from "react";
import Layout from "../../components/Layout/Layout";
// import "../faq/style.css";
// import Accordian from "./components/accordion";
import { Helmet } from "react-helmet-async";

const TOS = () => {
  return (
    <Layout>
      <Helmet>
        <title>Terms and Conditions</title>
        <meta
          name="description"
          content="The first decentralized business accelerator in the world. IP-Captain is the first business accelerator using smart accounts and account abstraction."
        />
      </Helmet>
      <div className="faq-page">
        <div className="container">
          <h1 className="title">IP-Captain - Terms and Conditions</h1>
          <div className="accordion tos-container">
            <div className="tos-item">
              <h2>1. Acceptance of Terms</h2>
              <p>
                By participating in IP-Captain ("the Accelerator"), you agree to
                comply with and be bound by these Terms and Conditions. If you
                do not agree with any part of these terms, please do not
                participate in the Accelerator.
              </p>
            </div>
            <div className="tos-item">
              <h2>2. tos-item</h2>
              <p>
                To be eligible for participation, applicants must meet the
                criteria outlined in the Accelerator's application process.
                IP-Captain reserves the right to determine eligibility at its
                sole discretion.
              </p>
            </div>
            <div className="tos-item">
              <h2>3. Intellectual Property</h2>
              <p>
                Participants retain ownership of their intellectual property.
                However, by participating, participants grant IP-Captain a
                limited license to use their company name and logo for
                promotional purposes.
              </p>
            </div>
            <div className="tos-item">
              <h2>4. Confidentiality</h2>
              <p>
                Participants may be exposed to confidential information during
                the program. Participants agree not to disclose any confidential
                information without the written consent of the disclosing party.
              </p>
            </div>
            <div className="tos-item">
              <h2>5. Program Structure</h2>
              <p>
                IP-Captain reserves the right to modify the structure, content,
                and duration of the program as deemed necessary for the success
                of participants and the overall effectiveness of the
                Accelerator.
              </p>
            </div>
            <div className="tos-item">
              <h2>6. Mentorship and Coaching</h2>
              <p>
                Participation in the Accelerator includes access to mentorship
                and coaching services. Participants agree to engage respectfully
                and professionally with mentors and coaches and to follow their
                guidance to the best of their ability.
              </p>
            </div>
            <div className="tos-item">
              <h2>7. Responsibilities</h2>
              <p>
                Participants are responsible for their own travel,
                accommodation, and any associated expenses incurred during the
                program. IP-Captain is not liable for any costs incurred by
                participants.
              </p>
            </div>
            <div className="tos-item">
              <h2>8. Termination</h2>
              <p>
                IP-Captain reserves the right to terminate a participant's
                involvement in the program if they violate the terms and
                conditions or engage in behavior deemed detrimental to the
                Accelerator's community
              </p>
            </div>
            <div className="tos-item">
              <h2>9. Liability:</h2>
              <p>
                Participants acknowledge that participation in the Accelerator
                involves inherent risks. IP-Captain is not liable for any
                direct, indirect, incidental, special, or consequential damages
                resulting from participation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TOS;
