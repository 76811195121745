import LoadingGif from '../../assets/icons/loading.gif'

const Loading = () => {
    return(
        <div style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',height:'100vh'}}>
            <img src={LoadingGif} alt='' />
        </div>
    ) 
  }

export default Loading