import React from "react";
import features1 from "../images/features1.webp";
import features2 from "../images/features2.webp";
import features3 from "../images/features3.webp";
type Props = {};

const FeaturesSection = (props: Props) => {
  return (
    <div className="features-section-container">
      <div className="features-section">
        <h1>Our Features</h1>

        <div className="features-card-container">
          <div className="features-card">
            <img src={features1} alt="" />

            <p>
              <span>Create DAOs:</span> Empowering clients with Decentralized
              Autonomous Organizations (DAOs), fostering collaboration and
              decision-making in our accelerator program.
            </p>
          </div>
          <div className="features-card">
            <img src={features2} alt="" />
            <p>
              <span> Mentorship: </span>Entrepreneurs gain access to experienced
              mentors who provide guidance, insights, and advice based on their
              own experiences. Mentorship is invaluable for helping startups
              navigate challenges and make informed decisions.
            </p>
          </div>
          <div className="features-card">
            <img src={features3} alt="" />
            <p>
              <span>Business Plan Refinement:</span> The platform offers
              assistance in fine-tuning business plans. This service can involve
              improving market research, financial projections, and overall
              business strategy to present a compelling case to potential
              investors and partners.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
